import React from 'react';

import { AuthUserContext, withAuthorization } from '../Session';
import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';

const AccountPage = () => (
    <AuthUserContext.Consumer>
    {authUser => (
      <div>
        <h1>Account: {authUser.email}</h1>
        <PasswordForgetForm />
        <PasswordChangeForm />
      </div>
    )}
  </AuthUserContext.Consumer>
  );

/* const App = () => (
    <div>
        <h1>Account</h1>
    </div>
); */

/* const App = () => {
    let ceco = 123;
    return (
        <div className="App">
            {ceco}
        </div>
    );
}; */

const condition = authUser => !!authUser;

export default withAuthorization(condition)(AccountPage);