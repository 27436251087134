import React, { Component } from 'react';
import { compose } from 'recompose';
import { withAuthorization } from '../Session';

import { withFirebase } from '../Firebase';

class AdminPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.users().on('value', snapshot => {
      const usersObject = snapshot.val();

      let usersList = [];
      if (usersObject) {
        usersList = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }));
      }

      this.setState({
        users: usersList,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  render() {
    const { users, loading } = this.state;

    const pageContent =
          <div>
            <h1>Admin</h1>

            {loading && <div>Loading ...</div>}

            <UserList users={users} />
          </div>
      ;

    return pageContent;
  }
}

const UserList = ({ users }) => (
  <ul>
    {users.map(user => (
      <li key={user.uid}>
        <span>
          <strong>ID:</strong> {user.uid}
        </span>
        <span>
          <strong>E-Mail:</strong> {user.email}
        </span>
        <span>
          <strong>Username:</strong> {user.username}
        </span>
      </li>
    ))}
  </ul>
);


const condition = authUser => !!authUser;

const AdminPageExport = compose(
  withAuthorization(condition),
  withFirebase,
)(AdminPage);

export default AdminPageExport;